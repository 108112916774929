import request from "@/utils/request";

// 获取验证码
export function getCodeImg() {
    return request({
        url: '/captchaImage',
        headers: {
            isToken: false
        },
        method: 'get',
        timeout: 20000
    })
}

// 获取手机验证码
export function phoneCode(query){
    return request({
        url:'/captchaPhone',
        method:'get',
        params: query
    })
}

// 提交表单信息
export function infoCollectFn(data) {
    return request({
        url: '/infoCollect',
        method: 'post',
        data
    })
}